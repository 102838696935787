/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class GeneratedAdminService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation changeUserEmail
   */
  static readonly ChangeUserEmailPath = '/api/admin/users/change-email';

  /**
   * Changes the sign-in and contact email address of an user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeUserEmail()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  changeUserEmail$Response(params?: {
    'X-API-Version'?: string;
    body?: {
'userId': number;
'email': string;
'sendConfirmationMail': boolean;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAdminService.ChangeUserEmailPath, 'post');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Changes the sign-in and contact email address of an user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeUserEmail$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  changeUserEmail(params?: {
    'X-API-Version'?: string;
    body?: {
'userId': number;
'email': string;
'sendConfirmationMail': boolean;
}
  },
  context?: HttpContext

): Observable<void> {

    return this.changeUserEmail$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation reassignBidder
   */
  static readonly ReassignBidderPath = '/api/admin/bidders/reassign';

  /**
   * Assigns an existing bidder to another user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reassignBidder()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  reassignBidder$Response(params?: {
    'X-API-Version'?: string;
    body?: {
'bidderId': number;
'userId': number;
'makeBidderPrimary': boolean;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAdminService.ReassignBidderPath, 'post');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Assigns an existing bidder to another user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reassignBidder$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  reassignBidder(params?: {
    'X-API-Version'?: string;
    body?: {
'bidderId': number;
'userId': number;
'makeBidderPrimary': boolean;
}
  },
  context?: HttpContext

): Observable<void> {

    return this.reassignBidder$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation mergeUsers
   */
  static readonly MergeUsersPath = '/api/admin/users/merge';

  /**
   * Moves most of the source users data onto the target user, and deletes the source user afterwards.
   *
   * Note that on merge some data, like verifications and documents, will not get merged and get deleted instead.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mergeUsers()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  mergeUsers$Response(params?: {
    'X-API-Version'?: string;
    body?: {
'sourceUserId': number;
'targetUserId': number;
'newInumisId': string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, GeneratedAdminService.MergeUsersPath, 'post');
    if (params) {
      rb.header('X-API-Version', params['X-API-Version'], {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Moves most of the source users data onto the target user, and deletes the source user afterwards.
   *
   * Note that on merge some data, like verifications and documents, will not get merged and get deleted instead.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mergeUsers$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  mergeUsers(params?: {
    'X-API-Version'?: string;
    body?: {
'sourceUserId': number;
'targetUserId': number;
'newInumisId': string;
}
  },
  context?: HttpContext

): Observable<void> {

    return this.mergeUsers$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
