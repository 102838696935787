import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { nameof } from 'ts-simple-nameof';
import { UserProfileDto } from '@core-shared/generated/models/user-profile-dto';
import { GeneratedUserProfileService } from '@core-shared/generated/services/generated-user-profile.service';
import { createJsonPatchPath } from '@core-shared/misc/helpers';
import { Operation } from '@core-shared/generated/models/operation';
import * as Sentry from '@sentry/angular';


@Injectable({ providedIn: 'root' })
export class UserProfileService {

  public readonly $userProfile: BehaviorSubject<UserProfileDto | undefined> = new BehaviorSubject<UserProfileDto | undefined>(undefined);

  constructor(private generatedService: GeneratedUserProfileService) {
  }

  public getUserProfile(): Observable<UserProfileDto> {
    return this.generatedService.getUserProfile().pipe(tap((profile) => {
      this.$userProfile.next(profile);
      Sentry.setUser({ id: profile.id });
    }));
  }

  public setCulture(culture: string): Observable<UserProfileDto> {
    return this.patchUser([{
      path: createJsonPatchPath(nameof<UserProfileDto>((u) => u.settings.cultureName)),
      op: 'replace',
      value: culture
    }]);
  }

  private patchUser(operations: Operation[]): Observable<UserProfileDto> {
    return this.generatedService.patchUserProfile({ body: operations })
      .pipe(tap((u) => this.$userProfile.next(u)));
  }
}
