import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SecondFactorSignInRequestDto, SessionInfoDto, SignInRequestDto, SignInResponseDto, SignInResult, TwoFactorProvider, UserProfileDto } from '@core-shared/generated/models';
import { GeneratedSessionService } from '@core-shared/generated/services/generated-session.service';
import { UserProfileService } from '@core-shared/services/user-profile.service';
import * as Sentry from '@sentry/angular';

@Injectable({ providedIn: 'root' })
export class SessionService {

  public readonly $sessionInfo: BehaviorSubject<SessionInfoDto | undefined> = new BehaviorSubject<SessionInfoDto | undefined>(undefined);

  constructor(private generatedService: GeneratedSessionService,
              private userProfileService: UserProfileService,
              private router: Router,
              private route: ActivatedRoute,
              private toastr: ToastrService) {
  }

  public setSessionInfo(signInInfo: SessionInfoDto | undefined): void {
    if (signInInfo === this.$sessionInfo.value) {
      return;
    }
    this.$sessionInfo.next(signInInfo);
  }

  public getSessionInfoWithUserProfile(): Observable<{ sessionInfo: SessionInfoDto, userProfile: UserProfileDto }> {
    return this.generatedService.getSessionInfo()
      .pipe(tap((sessionInfo) => this.setSessionInfo(sessionInfo)))
      .pipe(switchMap((sessionInfo) => this.userProfileService.getUserProfile()
        .pipe(map((up) => ({ sessionInfo: sessionInfo, userProfile: up }))))
      );
  }

  public signIn(loginData: SignInRequestDto): Observable<{ signInResponse: SignInResponseDto, sessionInfo?: SessionInfoDto, userProfile?: UserProfileDto }> {
    return this.handleSignInResponse(this.generatedService.signIn({ body: loginData }));
  }

  public signInExternal(bearerToken: string): Observable<{ signInResponse: SignInResponseDto, sessionInfo?: SessionInfoDto, userProfile?: UserProfileDto }> {
    return this.handleSignInResponse(this.generatedService.signInExternal({ Authorization: `Bearer ${bearerToken}` }));
  }

  public twoFactorSignIn(data: SecondFactorSignInRequestDto): Observable<{ signInResponse: SignInResponseDto, sessionInfo?: SessionInfoDto, userProfile?: UserProfileDto }> {
    return this.handleSignInResponse(this.generatedService.twoFactorSignIn({ body: data }));
  }

  public twoFactorRecoveryCodeSignIn(recoveryCode: string): Observable<{ signInResponse: SignInResponseDto, sessionInfo?: SessionInfoDto, userProfile?: UserProfileDto }> {
    return this.handleSignInResponse(this.generatedService.twoFactorRecoveryCodeSignIn({ body: { recoveryCode } }))
      .pipe(tap((s) => {
        const codeCount = s.sessionInfo!.recoveryCodeCount as number;
        const msg = 'Sie haben sich mit einem Recovery-Passwort angemeldet. Verbleibende Recovery-Passwörter: ' + codeCount;
        if (codeCount < 4) {
          this.toastr.warning(msg);
        }
        else {
          this.toastr.info(msg);
        }
      }));
  }

  public requestTwoFactorCode(tokenProvider: TwoFactorProvider): Observable<void> {
    return this.generatedService.requestTwoFactorCode({ body: { tokenProvider: tokenProvider } });
  }

  public async signOut(requestedPath?: string, attachRouteToQuery: boolean = true): Promise<void> {
    if (this.$sessionInfo.value) {
      await firstValueFrom(
        this.generatedService.signOut({ body: { forgetTwoFactorClient: false } })
          .pipe(catchError(() => of(undefined))) // ignore errors and continue
          .pipe(tap(() => {
            this.setSessionInfo(undefined);
            Sentry.setUser(null);
          }))
      );
    }
    this.router.navigate(['/login'], { queryParams: (requestedPath && attachRouteToQuery) ? { req: requestedPath } : {}, replaceUrl: true });
  }

  private handleSignInResponse(req: Observable<SignInResponseDto>): Observable<{ signInResponse: SignInResponseDto, sessionInfo?: SessionInfoDto, userProfile?: UserProfileDto }> {
    return req
      .pipe(catchError((error) => of(error.error)))
      .pipe(switchMap((res) => res.result === SignInResult.Success
        ? this.getSessionInfoWithUserProfile().pipe(map((siup) => ({ signInResponse: res, ...siup })))
          .pipe(catchError((error) => {
            if (error.status === 403) {
              // TODO TRANSLATE
              this.toastr.error('Sie sind nicht für diese Seite berechtigt.');
            }
            throw error;
          }))
        : of({ signInResponse: res }))
      )
      .pipe(tap((result) => {
        if (result.signInResponse.result === SignInResult.Success) {
          if (result.sessionInfo && !result.sessionInfo.globalGrants?.length) {
            // TODO TRANSLATE
            this.toastr.error('Sie sind nicht für diese Seite berechtigt.');
          }
          else {
            // route to protected route when preserved in "req" query-param
            this.route.queryParams
              .pipe(take(1))
              .subscribe((params) => this.router.navigateByUrl(params['req'] ?? '/dashboard'));
          }
        }
      }));


  }
}
