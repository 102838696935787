import { EnvSettings } from '@core-shared/models/env-settings';
import { environment } from '@environment';
import * as Sentry from '@sentry/angular';

// TODO outsource to ng-shared
export function initSentry(settings: EnvSettings): void {
  if (!environment.production || !settings.sentryDsn?.length) {
    return;
  }
  Sentry.init({
    dsn: settings.sentryDsn,
    environment: settings.customerTag || 'MISSING_CUSTOMER_TAG!',
    release: settings.version,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    integrations: [
      Sentry.browserTracingIntegration({ enableHTTPTimings: true }),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
      Sentry.replayIntegration({
        blockAllMedia: true,
        maskAllInputs: true,
        maskAllText: true
        // unmask: ['auex-bidding-input', 'input-checkbox']
        /* capture specific events only: https://docs.sentry.io/platforms/javascript/guides/angular/session-replay/privacy#example-capturing-500-status-codes-only
         beforeAddRecordingEvent */
      }),
      Sentry.httpClientIntegration({ failedRequestStatusCodes: [400, [402, 599]] }) // ignore 401
    ],
    attachStacktrace: true,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend: (event) => {
      if (event.user) {
        delete event.user.ip_address;
      }
      return event;
    }
  });
  Sentry.setTag('application.type', 'Client');
}
