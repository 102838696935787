import { HttpClient, HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap, timeout } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { environment } from '@environment';
import { GeneratedSessionService } from '@core-shared/generated/services';
import { SessionService } from '@core-shared/services/session.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  private logoutToastRef?: ActiveToast<unknown>;
  private readonly pathsToExcludeFromErrorHandling: string[];

  constructor(private sessionService: SessionService,
              private http: HttpClient,
              private router: Router,
              private translateService: TranslateService,
              private toastr: ToastrService,
              private loader: LoadingBarService) {
    this.pathsToExcludeFromErrorHandling = [
      GeneratedSessionService.SignInPath,
      GeneratedSessionService.SignInExternalPath
      /*       GeneratedUserAccountService.EnableTwoFactorAuthenticatorPath,
            GeneratedUserAccountService.EnableTwoFactorEmailPath,
            GeneratedUserAccountService.DisableTwoFactorEmailPath,
            GeneratedUserAccountService.RemoveTwoFactorAuthenticatorPath,
            GeneratedUserAccountService.ChangePasswordPath,
            GeneratedUserAccountService.AssignExternalLoginPath,
            GeneratedUserAccountService.UnassignExternalLoginPath,
            GeneratedUserAccountService.GenerateTwoFactorRecoveryCodesPath */
    ];
    // const sessionInfo = isPlatformBrowser(this.platformId) ? localStorage.getItem(nameof<SessionService>((s) => s.$signInInfo)) : undefined;
  }


  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    if (req.url.includes('/api/')) {
      req = req.clone({
        setHeaders: {
          'Accept-Language': req.headers.get('Accept-Language') ?? this.translateService.currentLang ?? 'en-US',
          'X-API-Version': environment.apiVersion // TODO environmentService?
        }
      });
    }

    // Pass login request without modification
    // TODO ANPASSEN
    if (!this.sessionService.$sessionInfo.value) {
      return next.handle(req);
    }

    // TODO try login via one-click-login before logging out

    let started = false;
    const ref = this.loader.useRef('http');
    return next.handle(req)
      .pipe(tap(() => {
        if (!started) {
          ref.start();
          started = true;
        }
      }))
      .pipe(timeout(30000))
      .pipe(finalize(() => ref.complete()))
      .pipe(catchError((error) => this.handleError(error, req.url)));

  }

  private handleError(error: HttpErrorResponse, requestUrl: string): Observable<string> {
    if (error.status === 401 && !this.pathsToExcludeFromErrorHandling.includes(requestUrl)) {
      this.logoutToastRef?.toastRef.close();
      // TODO TRANSLATE
      this.logoutToastRef = this.toastr.info('Sitzung abgelaufen.');
      this.sessionService.signOut(this.router.url);
    }
    return throwError(error);
  }

}
